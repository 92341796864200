<template>
  <div class="station-alarm-container">
    <div class="device-data-area">
      <div class="alarm-data-query">
        <span class="ml10">{{showLang('com.tab.title')}}：</span>
        <Input type="text" v-model="filter.name" clearable :placeholder="showLang('com.tab.title')" style="width: 140px; margin-right: 10px;"></Input>
        {{showLang('com.export.cn.connid')}}：
        <Input type="text" v-model="filter.code" clearable :placeholder="showLang('com.export.cn.connid')" style="width: 140px; margin-right: 10px;"></Input>
        所在分组：
        <Select :placeholder="showLang('save.select')" v-model="filter.lightGroupId" style="width: 120px;margin-right: 10px;">
          <Option :value="0">{{showLang('com.state.all')}}</Option>
          <template v-for="(s, idx) in args.groups">
            <Option :value="s.id" :key="idx">{{s.name}}[{{s.code}}]</Option>
          </template>
        </Select>
        <Button size="default" type="primary" @click="search" style="margin-right: 10px;">{{showLang('com.op.query')}}</Button>
        <!-- <Button type="success" :loading="exportLoading" @click="exportData" style="margin-right: 10px;">{{showLang('com.op.export')}}</Button> -->
      </div>
      <div v-if="menus.length > 0" class="alarm-data-query">
        <template v-for="(cmd, idx) in menus">
          <Button v-if="idx < cmdcnt" :key="idx" type="info" size="default" style="margin-right: 5px" @click="clickCmd(cmd)">{{cmd.name}}</Button>
        </template>
        <Dropdown style="margin-right: 5px" v-if="menus.length > cmdcnt">
          <Button type="info">
            {{showLang('com.op.other')}}<Icon type="ios-arrow-down"></Icon>
          </Button>
          <div slot="list" class="btns-flex" style="width: 700px">
            <template v-for="(cmd, idx) in menus">
              <Button v-if="idx >= cmdcnt" :key="idx" type="info" size="default" style="margin: 5px" @click="clickCmd(cmd)">{{cmd.name}}</Button>
            </template>
          </div>
        </Dropdown>
        <Button v-if="menus.length > 0" type="success" size="default" style="margin-right: 5px" @click="sendFailed">{{showLang('com.but.failed.resend')}}</Button>
      </div>
      <div class="alarm-data-area" ref="table">
        <vxe-table border resizable show-overflow show-header-overflow keep-source ref="chkTable" :key="dataRefresh" stripe round @checkbox-change="selectChangeEvent" :data="devices" height="auto" :row-config="{isHover: true, height: rowHeight}" :loading="loading" :checkbox-config="{checkField: 'checked',highlight: true}">
          <vxe-column type="checkbox" width="60" fixed="left"></vxe-column>
          <vxe-column field="name" :title="showLang('com.tab.title')" fixed="left" width="150" sortable header-align="center"></vxe-column>
          <vxe-column field="code" :title="showLang('com.export.cn.id')" fixed="left" width="100" sortable header-align="center"></vxe-column>
          <vxe-column field="online" :title="showLang('com.state')" fixed="left" width="50">
            <template #default="params">
              <img :src="getStateImage(params)" />
            </template>
          </vxe-column>
          <vxe-column title="通道号" width="100" header-align="center">
            <template #default="params">
              <div class="split-row-area">
                <div v-if="params.row.enable1" :class="['split-row-item', params.row.enable1 && params.row.enable2 ? 'split-row-flag' : '']">通道一</div>
                <div v-if="params.row.enable2" class="split-row-item">通道二</div>
              </div>
            </template>
          </vxe-column>
          <vxe-column field="output1" title="亮度(%)" width="100" header-align="center">
            <template #default="params">
              <div class="split-row-area">
                <div v-if="params.row.enable1" :class="['split-row-item', params.row.enable1 && params.row.enable2 ? 'split-row-flag' : '']">{{ params.row.output1 }}</div>
                <div v-if="params.row.enable2" class="split-row-item">{{ params.row.output2 }}</div>
              </div>
            </template>
          </vxe-column>
          <vxe-column field="voltage" title="电压(V)" width="100" header-align="center"></vxe-column>
          <vxe-column field="current" title="电流(A)" width="100" header-align="center">
            <template #default="params">
              <div class="split-row-area">
                <div v-if="params.row.enable1" :class="['split-row-item', params.row.enable1 && params.row.enable2 ? 'split-row-flag' : '']">{{ params.row.current1 }}</div>
                <div v-if="params.row.enable2" class="split-row-item">{{ params.row.current2 }}</div>
              </div>
            </template>
          </vxe-column>
          <vxe-column field="power2" title="功率(W)" width="100" header-align="center">
            <template #default="params">
              <div class="split-row-area">
                <div v-if="params.row.enable1" :class="['split-row-item', params.row.enable1 && params.row.enable2 ? 'split-row-flag' : '']">{{ params.row.power1 }}</div>
                <div v-if="params.row.enable2" class="split-row-item">{{ params.row.power2 }}</div>
              </div>
            </template>
          </vxe-column>
          <vxe-column field="powerOn1" title="累计亮灯(分)" width="120" header-align="center">
            <template #default="params">
              <div class="split-row-area">
                <div v-if="params.row.enable1" :class="['split-row-item', params.row.enable1 && params.row.enable2 ? 'split-row-flag' : '']">{{ params.row.powerOn1 }}</div>
                <div v-if="params.row.enable2" class="split-row-item">{{ params.row.powerOn2 }}</div>
              </div>
            </template>
          </vxe-column>
          <vxe-column field="quality" title="累计能耗(W)" width="120" header-align="center"></vxe-column>
          <vxe-column field="devModeName1" title="控制方式" width="100" header-align="center">
            <template #default="params">
              <div class="split-row-area">
                <div v-if="params.row.enable1" :class="['split-row-item', params.row.enable1 && params.row.enable2 ? 'split-row-flag' : '']">{{ params.row.devModeName1 }}</div>
                <div v-if="params.row.enable2" class="split-row-item">{{ params.row.devModeName2 }}</div>
              </div>
            </template>
          </vxe-column>
          <vxe-column field="rssi" title="信号强度(dBm)" width="100" sortable header-align="center"></vxe-column>
          <vxe-column field="tempModule" title="模块温度" width="100" sortable header-align="center"></vxe-column>
          <vxe-column field="fireTimeString" title="状态上报时间" width="150" sortable header-align="center" fixed="left"></vxe-column>
          <vxe-column field="_message" :title="showLang('com.ins.result')" header-align="center" sortable width="300" fixed="right">
            <template #default="params">
              <div v-if="params.row._message=='getDayPlan2Look'">
                <Button size="default" type="primary" @click="getDayPlan2Look(params.row)" style="margin-right: 10px;">查看准时表</Button>
              </div>
              <div v-else>
                <div>{{params.row._message}}</div>
              </div>
            </template>
          </vxe-column>
          <vxe-column field="poleName" title="所在灯杆" width="200" sortable header-align="center"></vxe-column>
          <vxe-column field="groupName" title="所在分区" width="200" sortable header-align="center"></vxe-column>
          <vxe-column field="iccid" title="ICCID" width="180" sortable header-align="center"></vxe-column>
          <vxe-column field="imei" title="IMEI" width="150" sortable header-align="center"></vxe-column>
          <vxe-column field="hardware" title="硬件版本" width="200" sortable header-align="center"></vxe-column>
          <vxe-column field="software" title="软件版本" width="200" sortable header-align="center"></vxe-column>
        </vxe-table>
      </div>
      <div>
        <vxe-pager :layouts="pageLayouts" :current-page.sync="filter.index" :page-size.sync="filter.size" :total="total" :page-sizes='pageSizes' @page-change="handlePageChange"></vxe-pager>
      </div>
      <ModalArgEdit v-model="showArgModal" :command="cmdItem" :args="args" @saved="argsSaved" />
      <ModalCheckAuth v-model="showCheckAuthModal" :users="checkAuthModalUsers" @checked="cmdAuthUserChecked" />
      <ModalLightTable2 v-model="showEditModal2" :item="editItem"  @saved="dayPlanSaved"/>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import ModalArgEdit from './ModalArgEdit'
import ModalCheckAuth from './ModalCheckAuth'
import ModalLightTable2 from '@/components/config/timetable/lt168/planWeekLt168/ModalLightTable'
export default {
  name: 'CommandChannelIndex',
  components: {
    ModalArgEdit,
    ModalCheckAuth,
    ModalLightTable2
  },
  props: {
  },
  data() {
    return {
      loading: false,
      showEditModal2: false,
      rowHeight: 48,
      editItem: {},
      filter: {
        groupId: 0,
        gatewayId: '',
        name: "",
        code: "",
        planId1: 0,
        planId2: '',
        lightGroupId: 0,
        mode: 0,
        useType: 0,
        size: 20,//20 100 500 1000 5000
        index: 1,
      },
      args: {
        plan1s: [],
        plan2s: [],
        useTypes: [],
        modes: [],
        gateways: [],
        deviceTypes: [],
        groups: [],
      },
      originalDevices: [],
      isScreenSelected: false,
      clearOldLog: this.clearOldLogs,
      cmdType: 'light',
      showCheckAuthModal: false,
      checkAuthModalSuccessFunction: null,
      checkAuthModalUsers: [],
      showWaitingModal: false,
      showArgModal: false,
      showPlanModal: false,
      showCreateModal: false,
      showStartOtaModal: false,
      cmdItem: { item: {}, other: {}, args: {} },
      cmdcnt: 10,
      devices: [],
      total: 0,
      dataRefresh: 0,
      arrCmds: [],
      cmdResults: {},
      timer: null,
      menus: [],
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes', 'showLang']),
    ...mapState('auth', ['config']),
    ...mapState('cmd', ['cmdResultRefresh', 'commands', 'windowOnResize', 'clearOldLogs']),
    ...mapState('common', ['pageLayouts', 'pageSizes', 'coms6038names']),
    ...mapState('group', ['groups', 'groupTreeSelectedNode']),
  },
  watch: {
    clearOldLog() {
      this.$store.commit('cmd/setOldLogStatus', this.clearOldLog)
    },
    isScreenSelected() {
      this.screenSelected();
    },
    groupTreeSelectedNode() {
      this.initList();
    },

  },
  mounted: function () {
    this.getCommands();
    this.queryLightGroups();
    this.queryGateway();
    this.clearOldLog = this.clearOldLogs;
    this.timer = setInterval(this.setCmdResult, 500);
    window.eventBus.$on('paramCommandComing', params => {
      params.time = new Date().getTime();
      this.cmdResults[params.commandId] = params;
    });
    window.eventBus.$on('lampRealDataComing', params => {
      let lamps = this.devices.filter(p => p.id == params.id);
      for (let lamp of lamps) {
        for (let key in params.data) {
          this.$set(lamp, key, params.data[key])
        }
      }
    });
  },
  destroyed: function () {
    window.eventBus.$off('lampRealDataComing');
    window.eventBus.$off('paramCommandComing');
    clearInterval(this.timer);
  },
  methods: {
    getStateImage: function (params) {
      if (!params.row.online) {
        return this.img.tree.station.offliner
      } else {
        if (params.row.alarm) {
          if (params.row.running) {
            return this.img.tree.station.alarmOn
          } else {
            return this.img.tree.station.alarmOff
          }
        } else {
          if (params.row.running) {
            return this.img.tree.station.onlineOn
          } else {
            return this.img.tree.station.onlineOff
          }
        }
      }
    },
    handlePageChange({ currentPage, pageSize }) {
      this.filter.index = currentPage
      this.filter.size = pageSize
      this.initList()
    },
    search: function () {
      this.filter.index = 1;
      this.initList();
    },
    initList: function () {
      if (this.loading) return;
      this.filter.groupId = this.groupTreeSelectedNode.id;
      this.loading = true;
      this.$axios.post(`device/light/QueryLightLt168State`, this.filter).then(res => {
        this.loading = false;
        if (res.code == 0) {
          this.$set(this, 'total', res.data.count);
          this.$set(this, 'devices', res.data.list);
          this.calcSuccessRate();
          let muti = false;
          for (let item of res.data.list) {
            if (item.enable1 && item.enable2) {
              muti = true;
              break;
            }
          }
          this.rowHeight = muti ? 60 : 48;
        }
      });
    },
    queryLightGroups: function () {
      this.$axios.post(`device/light/QueryGroup`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this.args, 'groups', res.data);
        }
      });
    },
    getLampUseTypes: function () {
      this.$axios.post(`device/light/GetLampUseTypes`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this.args, 'useTypes', res.data);
        }
      });
    },
    getLampControlModes: function () {
      this.$axios.post(`device/light/GetLampControlModes`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this.args, 'modes', res.data);
        }
      });
    },
    queryLampTypes: function () {
      this.$axios.post(`device/light/QueryLampTypes`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this.args, 'deviceTypes', res.data);
        }
      });
    },
    queryGateway: function () {
      this.$axios.post(`device/light/QueryGatewayConfig`, { index: 1, size: 5000 }).then(res => {
        if (res.code == 0) {
          this.$set(this.args, 'gateways', res.data.list);
        }
      });
    },
    queryPlan1List: function () {
      this.$axios.post(`device/light/QueryPlanTimeList`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this.args, 'plan1s', res.data);
        }
      });
    },
    queryPlan2List: function () {
      this.$axios.post(`device/light/QueryPlanClockList`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this.args, 'plan2s', res.data);
        }
      });
    },
    getCommands: function () {
      this.$axios.post(`device/light/QueryLightLt168Cmds`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'menus', res.data);
        }
      });
    },
    screenSelected() {
      if (this.isScreenSelected) {
        this.devices = this.devices.filter(p => p.checked);
      } else {
        this.devices = this.originalDevices;
      }
    },
    setCmdResult: function () {
      for (let cmdId in this.cmdResults) {
        let ds = this.devices.filter(p => p._cmdId == cmdId);
        if (ds.length == 0) {
          if (new Date().getTime() - this.cmdResults[cmdId].time > 100000) {
            delete this.cmdResults[cmdId];
          }
          continue;
        } else {
          let item = this.cmdResults[cmdId];
          this.$set(ds[0], '_status', item.status);
          this.$set(ds[0], '_result', item.message);
          this.$set(ds[0], '_content', item.content);
          this.$set(ds[0], '_message', this.getCmdStatus(ds[0]));
          delete this.cmdResults[cmdId]
        }
      }
      this.calcSuccessRate();
    },
    calcSuccessRate: function () {
      let total = this.devices.length;
      let success = this.devices.filter(p => p.status == 9).length;
      let rate = 0;
      if (total <= 0) {
        rate = 0;
      } else {
        rate = (success * 100 / total).toFixed(2);
      }
      window.eventBus.$emit('commandSuccessRateChanged', { total, success, rate });
    },
    selectChangeEvent({ rowIndex }) {
      this.$refs.chkTable.reloadRow(this.list, null, rowIndex)
    },
    sendFailed: function () {
      let chks = this.$refs.chkTable.getCheckboxRecords();
      let cmds = [];
      let code = '';
      let args = {};
      for (let d of this.devices) {
        if ((d._status == 7 || d._status == 3 || d._status == 8) && chks.filter(p => p.id == d.id).length > 0) {
          this.$set(d, '_status', 0)
          this.$set(d, '_result', this.showLang('com.lighting.repeat.preparation'))
          this.$set(d, '_message', this.getCmdStatus(d))
          cmds.push(d.id);
          code = d._cmd;
          args = d._args;
        } else if (this.clearOldLog) {
          this.$set(d, '_status', 0)
          this.$set(d, '_result', '')
        }
      }
      let cmd = {
        code: code,
        list: cmds,
        checkUserId: 0,
        args: args
      };
      setTimeout(() => {
        this.sendCommand(cmd);
      }, 500)
    },
    cmdAuthUserChecked: function (params) {
      if (this.checkAuthModalSuccessFunction) {
        this.checkAuthModalSuccessFunction(params);
      }
    },
    checkCmdAuth: function (type, cmdCode, checkOkFun) {
      this.$axios.post(`//${this.domains.trans}/sys/setting/CheckCmdAuth`, { type, code: cmdCode }).then(res => {
        if (res.code != 0) return;
        if (!res.data.need) {
          checkOkFun({ userId: 0 });
          return;
        }
        this.checkAuthModalSuccessFunction = checkOkFun;
        this.checkAuthModalUsers = res.data.users;
        this.showCheckAuthModal = true;
      });
    },
    getDayPlan2Look(data){
      this.editItem = { isAdd: false, index: 0, data: data,arr: data._content.list };
      this.showEditModal2=true;
    },
    dayPlanSaved(){
      this.showEditModal2=false;
    },
    clickCmd: function (params) {
      // debugger;
      // console.log(params)
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if (chks.length == 0) {
        this.$Message.warning("请选择要控制的单灯");
        return;
      }
      if (params.code == "getDayPlan1" || params.code == "getDayPlan2") {
        params.needArgs = true;
      }
      if (params.code == "getPlanType" ) {
        params.needArgs = false;
      }
      if (params.code == 'getCmdCount') {
        let ids = chks.map(p => p.id);
        this.getCmdCount(ids);
        return;
      } else if (params.code == 'clearCmds') {
        let ids = chks.map(p => p.id);
        this.clearCmds(ids);
        return;
      } else if (params.needPswd || params.needArgs) {
        this.cmdItem = { paramsCode: params.code, code: params.code, name: params.name, pswd: params.needPswd, args: {} }
        this.showArgModal = true;
        return
      } else {
        this.checkCmdAuth(this.cmdType, params.code, (checkResult) => {
          if (this.clearOldLog) {
            for (let item of this.devices) {
              item._result = '';
              item._status = 0;
            }
          }
          let cmds = []
          for (let chk of chks) {
            cmds.push(chk.id);
          }
          let cmd = {
            code: params.code,
            list: cmds,
            checkUserId: checkResult.userId,
            args: params.args
          };
          this.sendCommand(cmd);
        });
      }
    },
    argsSaved: function (params) {
      this.checkCmdAuth(this.cmdType, params.paramsCode, (checkResult) => {
        this.showArgModal = false;
        if (this.clearOldLog) {
          for (let item of this.devices) {
            item._result = '';
            item._status = 0;
          }
        }
        let chks = this.$refs.chkTable.getCheckboxRecords();
        let cmds = []
        let ns = [];
        for (let chk of chks) {
          ns.push(chk.pname);
          cmds.push(chk.id);
        }
        let code = params.code;
        if (code == 'closeSingle' || code == 'openSingle') {
          code = 'handSingle';
        }
        let cmd = {
          code: code,
          list: cmds,
          checkUserId: checkResult.userId,
          args: params.args
        };
        if (params.code == 'update') {
          this.$Modal.confirm({
            title: this.showLang('com.op.prompt'),
            content:'确定要升级设备吗？',
            onOk: async () => {
              this.sendCommand(cmd);
            },
          });
        } else {
          if (params.code == 'startReadWhiteFlag') {
            this.cmdItem = { id: chks[0].id }
            this.showWaitingModal = true;
          }
          this.sendCommand(cmd);
        }
      });
    },
    getCmdCount: function (ids) {
      this.$axios.post(`device/light/QueryLampCount`, { ids }).then(res => {
        if (res.code == 0) {
          for (let devId in res.data) {
            let ds = this.devices.filter(p => p.id == devId);
            if (ds.length == 0) continue;
            this.$set(ds[0], '_cmd', 'getCmdCount');
            this.$set(ds[0], '_status', 9);
            this.$set(ds[0], '_result', res.data[devId]);
            this.$set(ds[0], '_message', `${this.showLang('com.ins.get.result')}：${res.data[devId]}`);
          }
        }
      })
    },
    clearCmds: async function (ids) {
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content: this.showLang('com.ins.clean.ins.dev'),
        onOk: async () => {
          this.$axios.post(`device/light/ClearLampQueue`, { ids }).then(res => {
            if (res.code == 0) {
              for (let devId in res.data) {
                let ds = this.devices.filter(p => p.id == devId);
                if (ds.length == 0) continue;
                this.$set(ds[0], '_cmd', 'clearCmds');
                this.$set(ds[0], '_status', 9);
                this.$set(ds[0], '_result', res.data[devId]);
                this.$set(ds[0], '_message', `${res.data[devId]}`);
              }
            }
          })
        },
      });
    },
    getCmdStatus: function (cmd) {
      switch (cmd._status) {
        case 0: return `${cmd._result}`;
        case 2: return this.showLang('cmd.has.sent') + this.showLang('com.ins.dev.reply');
        case 3: return cmd._result;
        case 4: return this.showLang('com.ins.execution') + `：${cmd._content.pkg} / ${cmd._content.total}`;
        case 5: return this.showLang('com.ins.fail.retry') + `：${cmd._result}`;
        case 7: return this.showLang('cmd.has.timeout');
        case 8: return this.showLang('com.ins.fail') + `：${cmd._result}`;
        case 9:
          console.log('get cmd result', cmd);
          switch (cmd._cmd) {
            case 'getChipVoltage': return `通道号：${cmd._content.ch}；电压系数：${cmd._content.v}`;
            case 'getChipCurrent': return `通道号：${cmd._content.ch}；电流系数：${cmd._content.v}`;
            case 'getChipPower': return `通道号：${cmd._content.ch}；电流系数：${cmd._content.v}`;
            case 'getStationInfo': {
              return `curr_rat：${cmd._content.currRat}，dlBler：${cmd._content.dlBler}，UpBler：${cmd._content.UpBler}，RSRP：${cmd._content.rsrp}，RSRQ：${cmd._content.rsrq}`;
            }
            case 'getSigFlag': return +`${this.showLang('com.ins.success')}:${this.showLang('com.op.open')}：${cmd._content.on}，${this.showLang('com.op.shut')}：${cmd._content.off}`;
            case 'getAPN': return `APN:${cmd._content.apn}, user: ${cmd._content.user}, pswd: ${cmd._content.pswd}`;
            case 'getclock': return this.showLang('com.ins.success') + `：${cmd._content.nowTime}`;
            case 'getGroup': return this.showLang('com.ins.success') + `：${this.showLang('com.group.number')} ${cmd._content.no}`;
            case 'getInfo': {
              let result = '';
              if (cmd._content.appcode) {
                result += `应用编号：${cmd._content.appcode}；`
              }
              if (cmd._content.appversion) {
                result += `应用版本：${cmd._content.appversion}；`
              }
              if (cmd._content.hardware) {
                result += `硬件版本：${cmd._content.hardware}；`
              }
              if (cmd._content.software) {
                result += `软件版本：${cmd._content.software}；`
              }
              if (cmd._content.manufacture) {
                result += `生产厂商：${cmd._content.manufacture}；`
              }
              return result;
              // this.showLang('com.site.hw')+`：${cmd._content.hardware}，${this.showLang('com.site.sw')}：${cmd._content.software}，${this.showLang('com.user.manufacturer')}：${cmd._content.manufacture || '-'}`;
            }
            case 'getSimInfo': return `ICCID：${cmd._content.iccid}，IMEI：${cmd._content.imei}，RSSI：${cmd._content.rssi}`;
            case 'getChipVolCurArgs': return this.showLang('cmd.result.chip.vol.cur', cmd._content.vol1, cmd._content.vol2, cmd._content.leakVol, cmd._content.cur1, cmd._content.cur2, cmd._content.leakCur);
            case 'getDayPlan1': {
              let str = '';
              for (let n = 0; n < 6; n++) {
                let item = cmd._content.list[n];
                if (!item) continue;
                str += this.showLang('com.date.period') + `${n + 1}，`;
                str += '持续时间：' + item.interval + '；调光值：' + item.bright + '；联动值：' + item.union + "\n";
              }
              return '通道' + cmd._content.channel + "\n" + str;
            }
            case 'getDayPlan2': {
             
              return 'getDayPlan2Look';
            }
            case 'getPlanType': {
              let name2='',name1= '';
              if(cmd._content.type2==1){
                name2=this.showLang('com.but.ontime');
              }
              if(cmd._content.type2==0){
                name2=this.showLang('com.but.timing');
              }
              if(cmd._content.type1==1){
                name1=this.showLang('com.but.ontime');
              }
              if(cmd._content.type1==0){
                name1=this.showLang('com.but.timing');
              }
              return '通道1：'+name1+'，通道2：'+name2
            }
            case 'getNetwork': {
              return `IP:${cmd._content.host}, ${this.showLang('com.ins.port')}:${cmd._content.port},APN:${cmd._content.apn},APN用户:${cmd._content.user}`
           
            }
            case 'getUploadMode': {
              return `通讯上线上报: ${cmd._content.online?'开启':'关闭'},状态变化上报:${cmd._content.state?'开启':'关闭'},定时上报:${cmd._content.time}分钟,最小上报间隔:${cmd._content.interval}秒`
           
            }
            case 'getNetArgs': {
              return `TCP超时: ${cmd._content.init}分钟,模块复位超时:${cmd._content.reset}秒,发送心跳间隔:${cmd._content.heart}秒`
           
            }
            case 'getOutputArgs': {
              return `调光步长: ${cmd._content.step},最小值:${cmd._content.min}`
           
            }
            case 'getClockArgs': {
              return `自动校时: ${cmd._content.auto}秒`
           
            }
            case 'getRatedPower': {
              return `1通道额定功率: ${cmd._content.power1}(W),2通道额定功率: ${cmd._content.power2}(W),`
           
            }
            case 'getLuxOpenValue': return `${this.showLang('com.ins.light.val')}:${cmd._content.lux}`
            case 'getAlarmStatus': {
              return `${this.showLang('com.lighting.V.alarm')}:${cmd._content.voltage ? this.showLang('com.state.enable') : this.showLang('com.state.disable')}, ${this.showLang('com.lighting.I.alarm')}：${cmd._content.current ? this.showLang('com.state.enable') : this.showLang('com.state.disable')}, ${this.showLang('com.lighting.T.alarm')}：${cmd._content.temp ? this.showLang('com.state.enable') : this.showLang('com.state.disable')}, ${this.showLang('com.state.is.gyro.police')}：${cmd._content.gyro ? this.showLang('com.state.enable') : this.showLang('com.state.disable')}, ${this.showLang('com.state.is.leac.police')}：${cmd._content.leac ? this.showLang('com.state.enable') : this.showLang('com.state.disable')}, ${this.showLang('com.state.is.leav.police')}：${cmd._content.leav ? this.showLang('com.state.enable') : this.showLang('com.state.disable')}`
            }
            case 'getLeakageAlarmLevel': {
              return `${this.showLang('com.stat.level1')}：${cmd._content.l1}; ${this.showLang('com.stat.level2')}：${cmd._content.l2}; ${this.showLang('com.stat.level3')}：${cmd._content.l3}`;
            }
            case 'getLeakageAlarmEnable': {
              return `${this.showLang('com.state')}：${cmd._content.enable ? this.showLang('com.state.enable') : this.showLang('com.state.disable')}; ${this.showLang('com.date.check')}：${cmd._content.check}; ${this.showLang('com.ins.record.num')}：${cmd._content.count}`;
            }
            case 'getLeakageAlarmStatus': {
              return `${this.showLang('com.state')}：${this.leakagAlarmeStatus[cmd._content.level]}; ${this.showLang('com.ins.cl.val')}：${cmd._content.val};`;
            }
            case 'getUnionParam': {
              return `
              <p>${this.showLang('com.lighting.delay.lamp1')}：${cmd._content.keep1}s</p>
              <p>${this.showLang('com.lighting.delay.lamp2')}：${cmd._content.keep2}s</p>
              <p>${this.showLang('com.lighting.is.sensing')}：${cmd._content.sensor == 1 ? this.showLang('com.state.effective') : this.showLang('com.state.invalid')}；</p>
              <p>${this.showLang('com.lighting.is.signal')}：${cmd._content.txen > 10 ? this.showLang('com.state.effective') : this.showLang('com.state.invalid')}；</p>
              <p>${this.showLang('com.lighting.send.cycle')}：${cmd._content.period}s</p>
              <p>${this.showLang('com.lighting.linkage.number')}：${cmd._content.rfsn}；</p>
              <p>433${this.showLang('com.lighting.send.power')}：${cmd._content.txpower}；</p>
              <p>433${this.showLang('com.lighting.channel')}：${cmd._content.channel}；</p>
              <p>${this.showLang('com.lighting.response.range')}：${cmd._content.range}；</p>
              <p>${this.showLang('com.lighting.is.put.signal')}：${cmd._content.rxen == 1 ? this.showLang('com.state.receive') : this.showLang('com.state.no.receive')}；</p>
              <p>${this.showLang('com.lighting.linkage.mode')}：${cmd._content.mode == 1 ? this.showLang('com.lighting.Linkage.control') : this.showLang('com.lighting.lamp.contact')}；</p>
              <p>${this.showLang('com.lighting.repeat.num')}：${cmd._content.repeat}；</p>
              `
            }
            case 'getSNID': {
              return `SNID: ${cmd._content.snid}`;
            }
            case 'getRelayLevel': {
              return `${cmd._content.level}`;
            }
            case 'getAlarmRange': {
              return `电压范围：${cmd._content.vLow} - ${cmd._content.vHigh} V，1通道功率：${cmd._content.pLow1}-${cmd._content.pHigh1}%，2通道功率：${cmd._content.pLow2}-${cmd._content.pHigh2}%，温度范围：${cmd._content.tHigh} - ${cmd._content.tLow} ℃，降功率调光值：${cmd._content.lowBright}`;
              // return `间隔时间：${cmd.content.interval}分，电压范围：${cmd.content.vLow} - ${cmd.content.vHigh} V，电流范围：${cmd.content.cLow} - ${cmd.content.cHigh} A，温度范围：${cmd.content.tLow} - ${cmd.content.tHigh} ℃`
            }
            case 'getAdjustParams': return `${this.showLang('com.lighting.V.coefficient')}:${cmd._content.u}, ${this.showLang('com.lighting.I.coefficient')}:${cmd._content.i}, ${this.showLang('com.lighting.P.coefficient')}:${cmd._content.p}`;
            case 'getLatLng': return `${this.showLang('com.but.gis.get.result', cmd._content.lng, cmd._content.lat)}`;
            case 'getLatLngArgs': {
              let result = this.showLang('com.ins.ch') + cmd._content.channel + ',';
              result += (cmd._content.enable ? this.showLang('com.state.enable') : this.showLang('com.state.disable')) + ',\n';
              let i = 1;
              for (let item of cmd._content.list) {
                result += this.showLang('com.date.time') + i + ', '
                  + this.showLang('com.lighting.op') + ': ' + item.bright + ', '
                  + this.showLang('com.lighting.oc') + ': ' + item.color + ','
                  + this.showLang('com.date.delayed') + ': ' + item.delay + this.showLang('unit.time.min') + '; \n';
                i++;
              }
              return `${this.showLang('com.but.gis.args.get.result', result)}`;
            }
            case 'getVoltageOutputArgs': {
              return `调光线0V输出基准值:${cmd._content.v00}，调光线10V输出基准值：${cmd._content.v10}`;
            }
            case 'getLoraWork': {
              return `信道:${cmd._content.ch}，速率：${cmd._content.frq}`;
            }
            default: return this.showLang('com.ins.success');
          }
      }
    },
    sendCommand: function (cmd) {
      if (this.loading) return;
      if (cmd.list.length == 0) return;
      this.$set(this, 'cmdResults', {});
      for (let d of this.devices) {
        if (cmd.list.includes(d.id)) {
          this.$set(d, '_message', '')
        }
      }
      setTimeout(() => {
        this.loading = true;
        this.$axios.post(`device/light/SendLightCommand`, cmd).then(res => {
          this.loading = false;
          if (res.code == 0) {
            for (let item of res.data.list) {
              let ds = this.devices.filter(p => p.id == item.id);
              if (ds.length == 0) continue;
              this.$set(ds[0], '_cmd', cmd.code);
              this.$set(ds[0], '_args', cmd.args);
              this.$set(ds[0], '_cmdId', item.success ? item.message : '');
              this.$set(ds[0], '_status', item.success ? 1 : 8);
              this.$set(ds[0], '_content', {});
              this.$set(ds[0], '_result', '');
              this.$set(ds[0], '_message', item.success ? '指令已进入发送队列' : item.message);
            }
          }
        });
      }, 100);
    },
  }
}
</script>
<style scoped>
.station-alarm-container {
  height: 100%;
  display: flex;
  /* flex-direction: column;
  align-content: stretch; */
  /* padding-right: 40px; */
  /* border: solid 1px red; */
}
.device-data-area {
  margin-left: 10px;
  border: solid 1px #dcdee2;
  flex: auto;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  background-color: white;
}
.alarm-data-query {
  height: 40px;
  flex: none;
  background-color: white;
  margin: 3px 1px;
  display: flex;
  align-items: center;
  padding: 10px;
  white-space: nowrap;
}
.alarm-data-area {
  /* border: solid 1px red; */
  height: 100px;
  flex: auto;
  border-radius: 6px;
  padding: 0 5px;
}
.tab-active {
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(135, 159, 224);
  color: white;
}
.station-alarm-tabcontent {
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
}
.btns-flex {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
}
.split-row-area {
  /* border: solid 1px red; */
  display: flex;
  flex-direction: column;
  margin: 0 -10px;
}
.split-row-item {
  /* border-bottom: solid 1px red; */
  height: 30px;
  flex: none;
  line-height: 30px;
  text-align: center;
}
.split-row-flag {
  border-bottom: solid 1px #dcdee2;
}
</style>