<template>
  <div class="search-data-container">
    <div class="search-data-header">
      <div class="search-data-query">
        {{showLang('com.device.box.own')}}
        <Select :placeholder="showLang('save.select')" v-model="form.stationId" style="width: 150px; margin-right: 10px;">
          <template v-for="(item, idx) in selectedStations">
            <Option :value="item.id" :key="idx">{{item.name}}</Option>
          </template>
        </Select>
        {{showLang('com.date.start')}}：
        <DatePicker v-model="form.start" type="datetime" :placeholder="showLang('com.date.start')" style="width: 170px; margin-right: 10px;"></DatePicker>
        {{showLang('com.date.end')}}：
        <DatePicker v-model="form.end" type="datetime" :placeholder="showLang('com.date.end')" style="width: 170px; margin-right: 10px;"></DatePicker>
        <Button v-if="funCodes('spsv')" type="info" :loading="loading" @click="getList">{{showLang('com.op.query')}}</Button>
      </div>
      <div class="search-data-view">
        {{showLang('com.data.show')}}：
        <Select :placeholder="showLang('save.select')" v-model="viewCode" style="width: 180px; margin:3px 10px; flex: none;">
          <template v-for="(item, idx) in views">
            <Option :value="item.code" :key="idx">{{item.name}}</Option>
          </template>
        </Select>
        <Button v-if="funCodes('spse')" type="success" @click="exportData">{{showLang('com.op.export')}}</Button>
      </div>
    </div>
    <div class="switchType">
      <div :class="['switchType-tab fillet-left',  showTable? 'tab-active' : '']" @click="showTable=!showTable">
        {{showLang('com.data')}}</div>
      <div :class="['switchType-tab fillet-right',  showTable? '' : 'tab-active']" @click="showTable=!showTable">
        {{showLang('com.data.chart')}}</div>
    </div>
    <div class="search-data-content" ref="table1" v-if="showTable">
      <uTable :option="tabData" :tabHead="tabHead" :tabHeight="tabHeight"></uTable>
      <Spin size="large" fix v-if="loading"></Spin>
    </div>
    <div class="search-data-chart" v-if="!showTable">
      <lineChart :option="chartsLineData" id="chartsLineData" :key="timer" v-if="isShow"></lineChart>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import uTable from '../table/Index'
import exportXlsx from '@/components/xlsx/exportXlsx'
import lineChart from '@/components/charts/line'
export default {
  name: 'SearchPowerboxIndex',
  components: {
    uTable,
    lineChart,
  },
  props: {
  },
  data() {
    return {
      loading: false,
      curTab: 'group',
      tabHeight: 1,
      tabData: [//列表数据
      ],
      tabHead: { //头部标题
        // name: "名字",
        // sex: "性别",
        // age: "年龄",
      },
      form: {
        stationId: 0,
        start: "",
        end: "",
      },
      contactTexts: {
        0: '停止',
        1: '手动',
        2: '遥控',
        3: '时控',
      },
      timetable: {
        "0": "关灯时段",
        "1": "亮灯时段",
        "2": "未启用",
        "-1": "未设置",
        "-2": "已删除",
      },
      viewCode: '',
      showTable: true,
      views: [],
      history: [],
      chartsLineData: {
        color: ["#4166F5", "#0DEEBC", "#C96CFF"],
        title: "",
        x: ["Mon"],
        unit: "",
        legend: {
          data: ['Email'],
          left: "right",
        },
        series: [
          {
            name: "亮灯率",
            data: [150],
          },
        ],
        grid: {
          top: "5%", // 等价于 y: '16%'
          left: "40",
          right: "40",
          bottom: "3%",
          containLabel: true,
        },
        setSeries: {
          smooth: true, //平滑折线图
        },
      },
      timer: "",
      isShow: false,
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes', 'showLang']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['weeks', 'productCodes', 'alarmTypes', 'alarmLevels']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    selectedStations: function () {
      return this.selectedDevices.filter(p => p.type == this.productCodes.station);
    },
    currentPower: function () {
      if (this.selectedNode.type == this.productCodes.station) {
        return this.selectedNode;
      }
      return null;
    },
    doors: function () {
      if (!this.currentPower) return [];
      return this.currentPower.params.filter(p => p.type == 6);
    },
    locks: function () {
      if (!this.currentPower) return [];
      return this.currentPower.params.filter(p => p.type == 7);
    },
    smokes: function () {
      if (!this.currentPower) return [];
      return this.currentPower.params.filter(p => p.type == 8);
    },
    waters: function () {
      if (!this.currentPower) return [];
      return this.currentPower.params.filter(p => p.type == 9);
    },
    switchs: function () {
      if (!this.currentPower) return [];
      return this.currentPower.params.filter(p => p.type == 2);
    },
    output: function () {
      if (!this.currentPower) return [];
      return this.currentPower.params.filter(p => p.type == 3);
    },
    contactor: function () {
      if (!this.currentPower) return [];
      return this.currentPower.params.filter(p => p.type == 4);
    },
    leakage: function () {
      if (!this.currentPower) return [];
      return this.currentPower.params.filter(p => p.type == 11);
    },
  },
  watch: {
    viewCode() {
      this.createColAndData();
    },
    selectedNode() {
      this.setDefaultStation();
    },
    windowOnResize() {
      this.setTableHeight();
    },
  },
  mounted: function () {
    let now = new Date();
    this.form.end = now.format('yyyy-MM-dd HH:mm:ss');
    now.setDate(now.getDate() - 1);
    this.form.start = now.format('yyyy-MM-dd HH:mm:ss');
    setTimeout(() => {
      this.setTableHeight();
    }, 100);
    this.setDefaultStation();
    // this.getList();
    this.chartsLineData.series[0].name = this.showLang('com.stat.light.rate');
    this.timetable = {
      "0": this.showLang('com.state.offlight.period'),//关灯时段",
      "1": this.showLang('com.state.lighting.period'),//亮灯时段",
      "2": this.showLang('com.state.no.enable'),//未启用",
      "-1": this.showLang('com.not.set'),//未设置",
      "-2": this.showLang('com.op.del2'),//已删除",
    }
    this.contactTexts = {
      0: this.showLang('com.op.cease'),//停止,
      1: this.showLang('com.op.hand'),//手动,
      2: this.showLang('com.op.remote'),//遥控,
      3: this.showLang('com.op.time.control'),//时控,
    }
  },
  destroyed: function () {
  },
  methods: {
    setTableHeight: function () {
      if (!this.showTable) return;
      this.tabHeight = this.$refs.table1.clientHeight - 5;
      //this.tabHeight = 500 - 5;
    },
    setDefaultStation: function () {
      if (this.selectedStations.length == 0) {
        this.form.stationId = 0;
        return;
      }
      if (this.form.stationId == 0) {
        this.form.stationId = this.selectedStations[0].id;
        return;
      }
      let ss = this.selectedStations.filter(p => p.id == this.form.stationId)
      if (ss.length == 0) {
        this.form.stationId = this.selectedStations[0].id;
      }
    },
    exportData() {
      let dataArr = JSON.parse(JSON.stringify(this.tabData));
      let name = this.showLang('com.data.export');
      let vs = this.views.filter(p => p.code == this.viewCode);
      if (vs.length > 0) {
        name = vs[0].name + this.showLang('com.data.export');
      }
      exportXlsx(dataArr, this.tabHead, name);
    },
    getList() {
      this.form.start = new Date(this.form.start).format("yyyy-MM-dd HH:mm:ss");
      this.form.end = new Date(this.form.end).format("yyyy-MM-dd HH:mm:ss");
      let params = { sid: this.form.stationId, start: this.form.start, end: this.form.end };
      this.loading = true;
      this.$axios.post(`//${this.domains.trans}/station/history/PowerBox`, params).then(res => {
        this.loading = false;
        if (res.code == 0) {
          this.$set(this, 'history', res.data);
          this.calColViews();
        }
      });
    },
    calColViews: function () {
      let views = [];
      for (let p of this.history) {
        if (p.type == 1) {//总配电
          views.push({ id: p.id, type: p.type, code: `p${p.id}c1`, name: p.name + '-' + this.showLang('com.lighting.I'), cols: { ts: this.showLang('com.date.time'), ca: this.showLang('cmd.project.A.I') + '(A)', cb: this.showLang('cmd.project.B.I') + '(A)', cc: this.showLang('cmd.project.C.I') + '(A)' } });
          views.push({ id: p.id, type: p.type, code: `p${p.id}c2`, name: p.name + '-' + this.showLang('com.lighting.V'), cols: { ts: this.showLang('com.date.time'), ua: this.showLang('cmd.project.A.V') + '(V)', ub: this.showLang('cmd.project.B.V') + '(V)', uc: this.showLang('cmd.project.C.V') + '(V)' } });
          views.push({ id: p.id, type: p.type, code: `p${p.id}c3`, name: p.name + '-' + this.showLang('com.lighting.P'), cols: { ts: this.showLang('com.date.time'), pa: this.showLang('cmd.project.A.P') + '(W)', pb: this.showLang('cmd.project.B.P') + '(W)', pc: this.showLang('cmd.project.C.P') + '(W)' } });
          views.push({ id: p.id, type: p.type, code: `p${p.id}c4`, name: p.name + '-' + this.showLang('com.data.power.factor'), cols: { ts: this.showLang('com.date.time'), fa: this.showLang('cmd.project.A.P.factor'), fb: this.showLang('cmd.project.B.P.factor'), fc: this.showLang('cmd.project.C.P.factor') } });
          views.push({ id: p.id, type: p.type, code: `p${p.id}c5`, name: p.name + '-' + this.showLang('com.op.other'), cols: { ts: this.showLang('com.date.time'), e: this.showLang('com.lighting.q.val') + '(KWh)',battery: this.showLang('com.lighting.battery') + '(V)', cl: this.showLang('com.lighting.cl') + '(mA)', t: this.showLang('com.lighting.T.val') + '(℃)', tv: this.showLang('com.lighting.schedule.tab.val') } });
          views.push({ id: p.id, type: p.type, code: `p${p.id}c6`, name: p.name + '-' + this.showLang('com.op.locus.control'), cols: { ts: this.showLang('com.date.time'), ctrl: this.showLang('com.data.monitored.status') } });
        } else if (p.type == 12) {//电压监测
          views.push({ id: p.id, type: p.type, code: `p${p.id}c2`, name: this.showLang('com.lighting.V.monitor') + '-' + p.name, cols: { ts: this.showLang('com.date.time'), ua: this.showLang('cmd.project.A.V') + '(V)', ub: this.showLang('cmd.project.B.V') + '(V)', uc: this.showLang('cmd.project.C.V') + '(V)' } });
        } else if (p.type == 2) { //转换开关
          views.push({ id: p.id, type: p.type, code: `p${p.id}c1`, name: this.showLang('dev.switch') + '-' + p.name, cols: { ts: this.showLang('com.date.time'), v: this.showLang('com.state') } });
        } else if (p.type == 3) { //控制输出
          views.push({ id: p.id, type: p.type, code: `p${p.id}c1`, name: this.showLang('dev.output') + '-' + p.name, cols: { ts: this.showLang('com.date.time'), ov: this.showLang('com.output.status'), tv: this.showLang('com.ins.timeTable.status') } });
        } else if (p.type == 4) { //接触器
          views.push({ id: p.id, type: p.type, code: `p${p.id}c1`, name: this.showLang('dev.contact') + '-' + p.name, cols: { ts: this.showLang('com.date.time'), ov: this.showLang('com.associated.output.status'), sv: this.showLang('com.data.monitored.status') } });
        } else if (p.type == 5) {//支路配电
          views.push({ id: p.id, type: p.type, code: `p${p.id}c1`, name: this.showLang('com.device.branch') + '-' + p.name + this.showLang('com.lighting.I'), cols: { ts: this.showLang('com.date.time'), ca: this.showLang('cmd.project.A.I') + '(A)', cb: this.showLang('cmd.project.B.I') + '(A)', cc: this.showLang('cmd.project.C.I')+ '(A)' } }) ;
          views.push({ id: p.id, type: p.type, code: `p${p.id}c2`, name: this.showLang('com.device.branch') + '-' + p.name + this.showLang('com.lighting.V'), cols: { ts: this.showLang('com.date.time'), ua: this.showLang('cmd.project.A.V')+ '(V)', ub: this.showLang('cmd.project.B.V')+ '(V)', uc: this.showLang('cmd.project.C.V')+ '(V)' } });
          views.push({ id: p.id, type: p.type, code: `p${p.id}c3`, name: this.showLang('com.device.branch') + '-' + p.name + this.showLang('com.lighting.P'), cols: { ts: this.showLang('com.date.time'), pa: this.showLang('cmd.project.A.P') + '(W)', pb: this.showLang('cmd.project.B.P') + '(W)', pc: this.showLang('cmd.project.C.P')+ '(W)' } }) ;
          views.push({ id: p.id, type: p.type, code: `p${p.id}c4`, name: this.showLang('com.device.branch') + '-' + p.name + this.showLang('com.data.power.factor'), cols: { ts: this.showLang('com.date.time'), fa: this.showLang('cmd.project.A.P.factor'), fb: this.showLang('cmd.project.B.P.factor'), fc: this.showLang('cmd.project.C.P.factor') } });
          views.push({ id: p.id, type: p.type, code: `p${p.id}c5`, name: this.showLang('com.device.branch') + '-' + p.name + this.showLang('com.op.other'), cols: { ts: this.showLang('com.date.time'), cs: this.showLang('com.lighting.theft.current')+ '(mA)', cl: this.showLang('com.lighting.cl') + '(mA)', tv: this.showLang('com.lighting.schedule.tab.val') } });
        } else if (p.type == 6) { //柜门
          views.push({ id: p.id, type: p.type, code: `p${p.id}c1`, name: this.showLang('dev.door') + '-' + p.name, cols: { ts: this.showLang('com.date.time'), av: this.showLang('com.state.police.status'), sv: this.showLang('com.data.monitored.status') } });
        } else if (p.type == 7) { //门锁
          views.push({ id: p.id, type: p.type, code: `p${p.id}c1`, name: this.showLang('com.ope.nav.lock') + '-' + p.name, cols: { ts: this.showLang('com.date.time'), av: this.showLang('com.state.police.status'), sv: this.showLang('com.data.monitored.status'), ov: this.showLang('dev.output') } });
        } else if (p.type == 8) { //烟雾
          views.push({ id: p.id, type: p.type, code: `p${p.id}c1`, name: this.showLang('com.state.smoke') + '-' + p.name, cols: { ts: this.showLang('com.date.time'), av: this.showLang('com.state.police.status'), sv: this.showLang('com.data.monitored.status') } });
        } else if (p.type == 9) { //水浸
          views.push({ id: p.id, type: p.type, code: `p${p.id}c1`, name: this.showLang('com.state.water') + '-' + p.name, cols: { ts: this.showLang('com.date.time'), av: this.showLang('com.state.police.status') } });
        } else if (p.type == 11) { //漏电监测
          views.push({ id: p.id, type: p.type, code: `p${p.id}c1`, name: this.showLang('com.lighting.cl1') + '-' + p.name, cols: { ts: this.showLang('com.date.time'), cl: this.showLang('com.lighting.cl.val'), tv: this.showLang('com.state') } });
        }
      }
      this.$set(this, 'views', views);
      if (this.views.length > 0) {
        this.viewCode = this.views[0].code;
      } else {
        this.viewCode = '';
      }
      this.createColAndData();
    },
    createColAndData: function () {
      if (this.viewCode == '') {
        this.tabData = [];
        this.tabHead = {};
        return;
      }
      let vs = this.views.filter(p => p.code == this.viewCode);
      if (vs.length == 0) {
        this.tabData = [];
        this.tabHead = {};
        return;
      }
      let v = vs[0];
      this.$set(this, 'tabHead', v.cols);
      let ds = this.history.filter(p => p.id == v.id);
      if (ds.length == 0) {
        this.$set(this, 'tabData', []);
        return;
      }
      ds[0].list.sort((a, b) => {
        if (a.ts == b.ts) return 0;
        return a.ts > b.ts ? 1 : -1;
      });
      let list = []
      for (let item of ds[0].list) {
        let di = {};
        for (let key in v.cols) {
          di['ts'] = new Date(item.ts).format('yyyy-MM-dd HH:mm:ss');
          let v = item.content[key];
          if (v == -1) {
            v = this.showLang('com.state.not.measured');
          } else if (v == -2) {
            v = this.showLang('com.not.set');
          } else {
            if (key == "tv" && vs[0].type != 11) {
              v = this.timetable[item.content.tv];
            } else {
              let cfg = ds[0].cfg;
              switch (vs[0].type) {
                case 11:
                  if (key == 'tv') {
                    v = this.leakageStatus(item.content.tv, cfg)
                  }
                  break;
                case 2:
                  v = this.switchStatus(item.content);
                  break;
                case 3:
                  if (key == 'ov') {
                    v = item.content.av !== cfg.sv ? this.showLang('com.state.close') : this.showLang('com.state.break');
                  }
                  break;
                case 4:
                  if (key == 'ov') {
                    v = item.content.av !== cfg.sv ? this.showLang('com.state.close') : this.showLang('com.state.break');
                  } else if (key == 'sv') {
                    v = this.contactTexts[item.content.sv];
                  }
                  break;
                case 6:
                  if (key == 'av') {
                    v = item.content.av !== cfg.sv ? this.showLang('com.state.normal') : this.showLang('com.state.police');
                  } else if (key == 'sv') {
                    v = item.content.av !== cfg.sv ? this.showLang('com.state.close') : this.showLang('com.state.turn.on');
                  }
                  break;
                case 7:
                  if (key == 'av') {
                    v = item.content[key] !== cfg.sv ? this.showLang('com.state.normal') : this.showLang('com.state.police');
                  } else if (key == 'sv') {
                    v = item.content[key] !== cfg.sv ? this.showLang('com.state.close') : this.showLang('com.state.break');
                  }
                  break;
                case 8:
                  if (key == 'av') {
                    v = item.content[key] !== cfg.sv ? this.showLang('com.state.no.smoke') : this.showLang('com.state.police');
                  } else if (key == 'sv') {
                    v = item.content[key] !== cfg.sv ? this.showLang('com.state.no.smoke') : this.showLang('com.state.police');
                  }
                  break;
                case 9:
                  if (key == 'av') {
                    // v = item.content[key] !== cfg.av ? this.showLang('com.ins.waterless') : this.showLang('com.state.police');
                    v = this.waterState(item.content);
                  }
                  break;
              }
            }
            if (key == "ctrl" && vs[0].type != 11) {
              v = item.content[key] >= 0 ? (ds[0].cfg.ctrlv == item.content[key] ? this.showLang('com.op.internal') : this.showLang('com.op.external')) : '-';
            }
          }
          if (typeof v === 'number' && !Number.isNaN(v) && isFinite(v) && v != 0) {
            v =this.img.val(v,2)
          }
          di[key] = v;
        }
        list.push(di);
      }
      this.$set(this, 'tabData', list);
      this.chartData();
    },
    chartData() {
      this.chartsLineData.x = [];
      this.chartsLineData.series = [];
      Object.keys(this.tabHead).map(key => {
        if (key != "ts") {
          this.chartsLineData.series.push({
            name: key,
            data: [],
          })
        }
      })
      this.tabData.forEach(el => {
        this.chartsLineData.x.push(el.ts);
        this.chartsLineData.series.forEach((item, index) => {
          if (el[item.name] == this.showLang('com.state.not.measured') || el[item.name] == this.showLang('com.not.set')) {
            el[item.name] = 0;
          }
          this.chartsLineData.series[index].data.push(el[item.name])
        });
      });
      let vs = this.views.filter(p => p.code == this.viewCode);
      let cols = vs[0].cols;
      this.chartsLineData.legend.data = [];
      this.chartsLineData.series.forEach((item, index) => {
        this.chartsLineData.series[index].name = cols[item.name];
        this.chartsLineData.legend.data.push(item.name)
      });

      this.isShow = false;
      this.timer = new Date().getTime();
      this.isShow = true;
    },
    waterState: function (params) {
      if (params.s3 == params.a3) return this.showLang('water.type.level.3') + this.showLang('com.state.police');
      if (params.s2 == params.a2) return this.showLang('water.type.level.2') + this.showLang('com.state.police');
      if (params.s1 == params.a1) return this.showLang('water.type.level.1') + this.showLang('com.state.police');
      return this.showLang('com.ins.waterless');
    },
    leakageStatus: function (params, cfg) {
      if (params.cl > (cfg.lh)) return this.showLang('com.lighting.severe.leakage');
      if (params.cl > (cfg.ll)) return this.showLang('com.lighting.minor.leakage');
      return this.showLang('com.lighting.cl.no');
    },
    switchStatus: function (params) {
      if (params.hv == 1) return this.showLang('com.op.hand');
      if (params.rv == 1) return this.showLang('com.op.remote');
      if (params.tv == 1) return this.showLang('com.op.time.control');
      return this.showLang('com.op.cease');
    },
  }
}
</script>
<style scoped>
.switchType {
  height: 30px;
  display: flex;
  flex-direction: initial;
  margin: 10px 0 0 10px;
}
.switchType-tab {
  width: 68px;
  height: 30px;
  background: #eef4f9;
  color: #324252;
  font-size: 16px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
}
.fillet-left {
  border-top-left-radius: 6px;
}
.fillet-right {
  border-top-right-radius: 6px;
}
.switchType-tab.tab-active {
  background: #3880fc;
  color: #ffffff;
}
.search-data-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* border: solid 1px blue; */
}
.search-data-header {
  height: 40px;
  flex: none;
  display: flex;
  /* border: solid 1px blue; */
}
.search-data-content {
  height: 100px;
  flex: auto;
  margin-top: 10px;
  /* border: solid 1px blue; */
}
.search-data-query {
  width: 830px;
  flex: none;
  background-color: white;
  /* margin: 3px 1px; */
  display: flex;
  align-items: center;
  padding: 10px;
  /* border: solid 1px blue; */
  white-space: nowrap;
}
.search-data-view {
  width: 300px;
  flex: auto;
  display: flex;
  align-items: center;
  /* border: solid 1px blue; */
  white-space: nowrap;
  padding: 10px;
}
.search-data-chart {
  width: calc(100% - 20px);
  margin: 0 auto;
  height: 500px;
  position: relative;
}
.colorDeputy {
  position: absolute;
  right: 40px;
  display: flex;
}
.deputy-list {
  margin-right: 10px;
}
.deputy-list i {
  display: inline-block;
  width: 5px;
  height: 5px;
}
</style>