<template>
<div class="search-data-container">
  <div class="search-data-header">
    <div class="search-data-query">
      {{showLang('com.device.box.own')}}：
      <Select :placeholder="showLang('save.select')" v-model="form.stationId" style="width: 150px; margin-right: 10px;">
        <!-- <Option :value="0">所有配电柜</Option> -->
        <template v-for="(item, idx) in selectedStations">
          <Option :value="item.id" :key="idx">{{item.name}}</Option>
        </template>
      </Select>
      {{showLang('com.date.start')}}：
      <DatePicker v-model="form.start" type="datetime" style="width: 170px; margin-right: 10px;"></DatePicker>
      {{showLang('com.date.end')}}：
      <DatePicker v-model="form.end" type="datetime" style="width: 170px; margin-right: 10px;"></DatePicker>
      <Button v-if="funCodes('slov')" type="info" :loading="loading" @click="getList" style="margin-right: 10px;">{{showLang('com.op.query')}}</Button>
      <Button v-if="funCodes('sloe')" type="success" @click="exportData">{{showLang('com.op.export')}}</Button>
    </div>
  </div>
  <div class="search-data-content" ref="table1">
    <uTable :option="tabData" :tabHead="tabHead" :tabHeight="tabHeight" ></uTable>
    <Spin size="large" fix v-if="loading"></Spin>
  </div>
</div>
</template>
<script>
import { mapState,mapGetters } from 'vuex'
import uTable from '../table/Index'
import exportXlsx from '@/components/xlsx/exportXlsx'
export default {
  name: 'StationConfigIndex',
  components:{
     uTable,
  },
  props: {
  },
  data () {
    return {
      loading: false,
      curTab: 'group',
      tabHeight:1,
      tabData:[],
      tabHead:{ //头部标题
        stationName: "站点名称",
        branchName: "支路名称",
        open: '是否开灯',
        createTime: '发生时间',
        ca: "A相电流(A)",
        cb: "B相电流(A)",
        cc: "C相电流(A)",
      },
      form:{
        stationId: 0,
        start:"",
        end:"",
      },
      history: [],
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes',"showLang"]),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['weeks', 'productCodes', 'alarmTypes', 'alarmLevels']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    selectedStations: function(){
      return this.selectedDevices.filter(p => p.type == this.productCodes.station);
    },
    selectedStationIds: function(){
      let pids = this.selectedDevices.filter(p => p.type == this.productCodes.station).map(p => {return p.id});
      return pids;
    },
  },
  watch: {
    viewCode(){
      this.createColAndData();
    },
    selectedNode(){
      this.setDefaultStation();
    },
    windowOnResize(){
      this.setTableHeight();
    },
  },
  mounted: function(){
    let now = new Date();
    this.form.end = now.format('yyyy-MM-dd HH:mm:ss');
    now.setDate(now.getDate() - 3);
    this.form.start = now.format('yyyy-MM-dd HH:mm:ss');
    setTimeout(() => {
      this.setTableHeight();
    }, 100);
    this.setDefaultStation();
    // this.getList();
    this.tabHead={ //头部标题
        stationName:this.showLang('com.site.name'),
        branchName:this.showLang('com.device.branch.name'),
        open:this.showLang('com.is.open.light'),
        createTime:this.showLang('com.date.occurrence'),
        ca:this.showLang('cmd.project.A.I')+'(A)',
        cb:this.showLang('cmd.project.B.I')+'(A)',
        cc:this.showLang('cmd.project.C.I')+'(A)',
      }
  },
  destroyed: function(){
  },
  methods: {
    setTableHeight: function(){
      this.tabHeight = this.$refs.table1.clientHeight - 5;
    },
    setDefaultStation: function(){
      if (this.selectedStations.length == 0){
        this.form.stationId = 0;
        return;
      }
      if (this.form.stationId == 0) {
        this.form.stationId = this.selectedStations[0].id;
        return;
      }
      let ss = this.selectedStations.filter(p => p.id == this.form.stationId)
      if (ss.length == 0) {
        this.form.stationId = this.selectedStations[0].id;
      }
    },
    exportData(){
      let dataArr=JSON.parse(JSON.stringify(this.tabData));
      let name = this.showLang('history.data.switch.export');
      exportXlsx(dataArr, this.tabHead, name);
    },
    getList(){
      this.form.start = new Date(this.form.start).format("yyyy-MM-dd HH:mm:ss");
      this.form.end = new Date(this.form.end).format("yyyy-MM-dd HH:mm:ss");
      //请在左树选择站点
      // if (this.form.stationId == 0) {
      //   this.$message.error(this.showLang('com.save.err.station'));
      //   return;  
      // }
      let params = {sid: this.form.stationId, start: this.form.start, end: this.form.end};
      this.loading = true;
      this.$axios.post(`//${this.domains.trans}/station/search/branchopen`, params).then(res => {
        this.loading = false;
        if(res.code == 0){
          res.data.map(item => item.open = item.open ? this.showLang('com.state.lighting') : this.showLang('alarm.type.power.close'))
          this.$set(this, 'tabData', res.data);
          this.createColAndData();
        }
      });
    },
    createColAndData: function(){
    },
    switchTab: function(type){
      this.curTab = type;
    },
  }
}
</script>
<style scoped>
.search-data-container{
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* border: solid 1px blue; */
}
.search-data-header{
  height: 40px;
  flex: none;
  display: flex;
  /* border: solid 1px blue; */
}
.search-data-content{
  height: 100px;
  flex: auto;
  /* border: solid 1px blue; */
}
.search-data-query{
  width: 830px;
  flex: auto;
  background-color: white;
  /* margin: 3px 1px; */
  display: flex;
  align-items: center;
  padding: 10px;
  /* border: solid 1px blue; */
  white-space: nowrap;
}
.search-data-view{
  width: 300px;
  flex: auto;
  display: flex;
  align-items: center;
  /* border: solid 1px blue; */
  white-space: nowrap;
  padding: 10px;
}
</style>